import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import SEO from "@src/components/SEO";

function TeacherSignup() {
  const modalContext = useContext(ModalContext);
  let email: string;
  let schoolLeader = false;

  if (typeof window !== "undefined") {
    email = window.history?.state?.email;
    schoolLeader = window.location.search.includes("school-leader");
  }

  const showModalProps = email
    ? { fullPage: true, email, closeModalAction: () => navigate("/") }
    : { fullScreen: true, closeModalAction: () => navigate("/") };

  function showTeacherModal() {
    modalContext.showModal(ModalType.TeacherSignupForm, showModalProps);
  }

  function showSlModal() {
    modalContext.showModal(ModalType.SchoolLeaderSignupForm, showModalProps);
  }

  useEffect(() => {
    if (schoolLeader) {
      showSlModal();
    } else {
      showTeacherModal();
    }
    return () => {
      modalContext.hideModal();
    };
  }, []);
  return (
    <>
      <SEO noindex={true} />
      <div></div>
    </>
  );
}

export default TeacherSignup;
